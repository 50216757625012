import { LitElement, html } from 'lit-element';
import { repeat } from 'lit-html/directives/repeat.js';

class HomeEvent extends LitElement {

    constructor () {
        super();

        this.event = null;
        this.featured = null;
        this.compact = null;
    }

    createRenderRoot () {
        /**
        * Render template without shadow DOM. Note that shadow DOM features like
        * encapsulated CSS and slots are unavailable.
        */
        return this;
    }

    static get properties() {
        return {
            event: { type: Object },
            featured: { type: Boolean },
            compact: { type: Boolean }
        };
    }

    async saveUnsaveEvent(action) {

        const response = await fetch( `${ window.constants.eventApi }/event/${this.event.event_id}/${action.type}?userid=${ window.constants.userId }`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${ window.constants.eventApiToken }`
            }
        })
        .catch(_ => null);
        
        if (response && response.ok) {
          // Update the current event data
          this.event = await response.json();
          window.dispatchEvent( new CustomEvent( 'event_item_changed', { detail: this.event } ) );
        }
    }

    render() {
        if ( !this.event ) return html``;

        return html`
            <div class="HomeEvent ${ this.featured ? 'featured': '' } ${ this.compact ? 'compact': '' }">
                <img class="image" src="${ this.event.assets.thumbnail_landscape || this.event.assets.hero_landscape }" />
                <div class="info">

                    <div class="tags">
                        ${ this.event.tags ? html`
                            ${ this.event.tags.map( ( tag ) => html`<p class="tag body-s">${ this.event.copy[ tag ] }</p>` ) }
                        ` : '' }
                        ${ this.event.epoch === 'current' && this.event.type === 'live' ? html`<p class="tag body-s highlighted">${ window.copy.getClientInstance( 'home_tag_live' ) }</p>` : null }
                        ${ this.event.my_videos ? html`<p class="tag body-s highlighted">${ window.copy.getClientInstance( 'home_tag_my_videos' ) }</p>` : null }
                    </div>
                    
                    <h3 class="info-title headline-l">${ this.event.copy.title }</h3>

                    <div class="details">
                        ${ this.event.copy.date ? html`<p class="details-item body-m">${ this.event.copy.date }</p>` : '' }
                        ${ this.event.copy.location ? html`<p class="details-item body-m">${ this.event.copy.location }</p>` : '' }
                        ${ this.event.copy.livestream_times ? html`<p class="details-item body-m">${ this.event.copy.livestream_times }</p>` : '' }
                        ${ this.event.copy.duration ? html`<p class="details-item body-m">${ this.event.copy.duration }</p>` : '' }
                    </div>

                    <p class="body-m info-description">${ this.event.copy.tagline }</p>

                    <div class="actions">
                        ${ repeat( this.event.actions, ( action ) => (action.type === 'save' || action.type === 'unsave') ? html`
                            <a class="button tertiary" @click="${() => this.saveUnsaveEvent(action)}">${ this.event.copy[ action.copy_key ] }</a>
                        ` : html`
                            <a class="button ${ action.type === 'cancel' ? 'tertiary' : ( this.featured ? '' : 'secondary' ) }" href="${ action.url }">${ this.event.copy[ action.copy_key ] }</a>
                        ` ) }
                    </div>
                </div>
            </div>
        `;
    }

}

customElements.define( 'home-event', HomeEvent );
